import React from 'react';
import Avatar from '@components/Avatar';
import { Container, AvatarContainer, UserNameContainer, Inner, UserIdFrame, Label, TagContainer } from './style';
import { useEngagementUserProfile } from '../EngagementUserProfileProvider';
import {
  Font12G3W400,
  Font12G5W400,
  Font14G1W400,
  Font14G1W600,
  Font16G1W500,
  Font20G1W600,
  Spacing,
} from '@components/UtilsComponent';
import dayjs from 'dayjs';
import useTranslation from '@hooks/useTranslation';
import { TicketTagList } from '@components/Tag';
import { useCalculateWidth } from '@components/Tag/TicketTagList/useCalculateWidth';
import { isMobile } from 'react-device-detect';
import { ETicketNoteState, TicketNote } from '@components/TicketNote';
import { useTicketNote } from '@hooks/useTicketNote';
import { ITicket, IUser } from '@types';
import { useMember } from '@providers/MemberProvider';
import { isNotMyTicket } from '@utils/ticketStatus';
import { useSetting } from '@providers/SettingProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { ConfirmModal } from '@components/Modal';
import { useConfirm } from '@hooks/useConfirm';
import TextInput from '@components/TextInput';
import { useCustomName } from './useCustomName';
import { CUSTOM_NAME_MAX_LENGTH } from '@configs/constants';

const UserProfile = ({
  userProfile,
  ticket,
  onRefetch,
}: {
  userProfile: IUser;
  ticket: ITicket;
  onRefetch: () => void;
}) => {
  const t = useTranslation();
  const { ticketHistory, currentAssignedTicket } = useEngagementUserProfile();
  const tags = currentAssignedTicket.data?.tickets[0]?.tags ?? [];
  const { containerRef, tagRefs, displaySize } = useCalculateWidth(tags, 100000, 0);
  const { note, saveNoteMute, onUpdateNote } = useTicketNote(ticket);
  const { member, isAdmin } = useMember();
  const { permission } = useSetting();
  const { visible, onConfirm, onToggle } = useConfirm();
  const { name, updateNameMute, deleteMute, tempNameRef, canEdit, onChange, onSaveName } = useCustomName(
    userProfile?.displayName,
  );

  const _renderName = () => {
    // if custom name is exist
    if (userProfile?.displayName) {
      return (
        <div className="flex flex-col">
          <div>
            <Font20G1W600>
              {userProfile.displayName ?? ''}
              {ticket.status !== 'resolved' && canEdit ? (
                <FontAwesomeIcon
                  className="text-[16px] ml-[8px]"
                  role="button"
                  onClick={onToggle}
                  icon={faPen}
                  color="#BDBDBD"
                />
              ) : null}
            </Font20G1W600>
          </div>
          {/* default name */}
          <Font14G1W400>({userProfile.name ?? ''})</Font14G1W400>
        </div>
      );
    }
    // if custom name is not exist display the default name
    return (
      <Font20G1W600>
        {ticket.userInfo?.name ?? ''}{' '}
        {ticket.status !== 'resolved' && canEdit ? (
          <FontAwesomeIcon className="text-[16px] " role="button" onClick={onToggle} icon={faPen} color="#BDBDBD" />
        ) : null}
      </Font20G1W600>
    );
  };
  const canTakeNote =
    !permission.canTakeNote && !isAdmin() && (isNotMyTicket(ticket, member) || ticket.status === 'open');
  return (
    <Container>
      <>
        <Inner>
          <AvatarContainer>
            <Avatar
              width={120}
              height={120}
              channelType={ticketHistory.data && ticketHistory.data?.user?.channel?.type}
              img={ticketHistory.data && ticketHistory.data?.user?.image}
            />
          </AvatarContainer>
          <UserNameContainer>
            <div className="flex justify-center gap-x-[8px] items-center">{_renderName()}</div>
            <Spacing height={4} />
            <UserIdFrame>
              <Font12G3W400>{userProfile?.referenceId ?? ''}</Font12G3W400>
            </UserIdFrame>
            <ConfirmModal
              visible={visible}
              maxWidth={400}
              bodyWidth="100%"
              isDisabledConfirmBtn={tempNameRef.current === name}
              centered
              isLoading={updateNameMute.isLoading || deleteMute.isLoading}
              confirmBtnTxt={t('confirm.btn')}
              cancelBtnTxt={t('cancel.btn')}
              handleClose={() => {
                onChange(tempNameRef.current);
                onToggle();
              }}
              handleConfirm={() => {
                onConfirm(async () => {
                  try {
                    if (!ticket.userId) return;
                    await onSaveName(ticket.userId);
                    onRefetch();
                    onToggle();
                  } catch (error) {
                    onToggle();
                  }
                });
              }}
            >
              <Font20G1W600>{t('custom.name.title')}</Font20G1W600>
              <div className="mt-[8px]">
                <Font14G1W400>{t('custom.name.description1')}</Font14G1W400>
                <Font14G1W600 className="whitespace-pre-line">
                  {t('custom.name.description2', { agentName: ticket.userInfo?.name ?? '' })}
                </Font14G1W600>
              </div>
              <div className="mt-[16px] flex flex-col">
                <TextInput
                  value={name}
                  onChange={(e) => onChange(e.target.value)}
                  maxLength={CUSTOM_NAME_MAX_LENGTH}
                  placeholder={ticket.userInfo?.name ?? ''}
                />
                <div className="flex justify-end">
                  <Font12G5W400>
                    {name.length} / {CUSTOM_NAME_MAX_LENGTH}
                  </Font12G5W400>
                </div>
              </div>
            </ConfirmModal>
          </UserNameContainer>
        </Inner>
        {/* Detail */}
        <Spacing height={16} />
        <Inner>
          <div>
            <Label>{t('livechat.user.profile.channel.label')}</Label>
            <br />
            <Font16G1W500>{userProfile?.channel?.name ?? '-'}</Font16G1W500>
          </div>
          <Spacing height={16} />
          <div>
            <Label>{t('livechat.user.profile.join.date.label')}</Label>
            <br />
            <Font16G1W500>
              {(ticketHistory.data && dayjs(ticketHistory.data.user.createdAt).format('DD MMM YYYY')) ?? '-'}
            </Font16G1W500>
          </div>
          <Spacing height={16} />
          <div>
            <Label>{t('livechat.user.profile.segment.label')}</Label>
            <br />
            <Font16G1W500>-</Font16G1W500>
          </div>

          {isMobile ? (
            <div>
              <Label>{t('livechat.ticket.console.ticket.tags')}</Label>

              <TagContainer ref={containerRef}>
                <TicketTagList
                  tags={tags}
                  displaySize={displaySize === 100000 ? 100000 : displaySize}
                  tagRefs={tagRefs}
                  showHiddenMenu
                />
              </TagContainer>
            </div>
          ) : null}

          <Spacing height={16} />
        </Inner>
        <Spacing height={16} />
        <TicketNote
          state={saveNoteMute.isLoading ? ETicketNoteState.SAVING : ETicketNoteState.IDLE}
          isDisabled={canTakeNote}
          value={note}
          onChange={onUpdateNote}
        />
        <div className="mt-4 h-[1px] bg-[#E0E0E0] w-full" />
      </>
    </Container>
  );
};

export default UserProfile;
