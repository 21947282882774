import React, { useState } from 'react';
import { Card } from '@components/Card';
import { faTicketAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chart } from '@pages/components/Chart';
import dayjs from 'dayjs';
import { groupBy } from 'lodash';
import { SeriesOptionsType } from 'highcharts';
import useTranslation from '@hooks/useTranslation';
import { IFilterState } from '../useDashboardFilter';
import { FetchWrap } from '@components/FetchWrap';
import { CancelTokenSource } from 'axios';
import { ITicketActivityResponse } from '@types';
import { getTicketActivityAnalytic } from '@api/livechat/Analytic';
import { CardContent } from '@components/CardWrap';
import Select from '@components/Select';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
dayjs.extend(quarterOfYear);
type ITicketActivityProps = {
  filter: IFilterState;
};

const INTERVAL = {
  daily: 'daily',
  monthly: 'monthly',
  quarterly: 'quarterly',
  yearly: 'yearly',
} as const;

export const generateDateByIntervalForReal = (date: string, interval: keyof typeof INTERVAL) => {
  if (interval === 'daily') {
    return dayjs(date).format('DD MMM YY');
  } else if (interval === 'monthly') {
    return dayjs(date).format('MMM YY');
  } else if (interval === 'quarterly') {
    return `Q${dayjs(date).quarter()} ${dayjs(date).format('YY')}`;
  } else if (interval === 'yearly') {
    return dayjs(date).format('YYYY');
  } else {
    return dayjs(date).format('DD MMM YY');
  }
};

export const TicketActivity = ({ filter }: ITicketActivityProps) => {
  const t = useTranslation();
  const [selectedInterval, setSelectedInterval] = useState<keyof typeof INTERVAL>(INTERVAL.daily);

  return (
    <FetchWrap<ITicketActivityResponse, IFilterState>
      filter={filter}
      queryKey={['ticket-activity', filter, selectedInterval]}
      fetchFn={async (
        filter: IFilterState,
        cancelToken?: CancelTokenSource | undefined,
      ): Promise<ITicketActivityResponse> => {
        const {
          data,
        }: {
          data: {
            data: ITicketActivityResponse;
          };
        } = await getTicketActivityAnalytic({
          agents: filter.assignees,
          channels: filter.channels,
          tags: filter.tags,
          teams: filter.teams,
          startIsoStringDate: filter.startIsoStringDate.format(),
          endIsoStringDate: filter.endIsoStringDate.format(),
          interval: selectedInterval,
          cancelToken,
        });

        return data.data;
      }}
      renderFn={function ({
        isLoading,
        data,
        isError,
        refetch,
      }: {
        isLoading: boolean;
        data?: ITicketActivityResponse;
        isError: boolean;
        refetch: () => void;
      }): JSX.Element {
        const date = data?.map((item) => {
          return generateDateByIntervalForReal(item.intervalStartsAt, selectedInterval);
        });
        const opened = data?.map((item) => item.count.opened);
        const resolved = data?.map((item) => item.count.resolved);
        const series = [
          {
            name: 'opened',
            data: opened,
          },
          {
            name: 'resolved',
            data: resolved,
          },
        ] as SeriesOptionsType[];

        return (
          <Card
            maxHeight="420px"
            minHeight="420px"
            divider
            title={t('ticket.activity.title')}
            description={t('ticket.activity.description')}
            topLeftIcon={<FontAwesomeIcon icon={faTicketAlt} />}
            _renderCustomActionList={() => {
              return (
                <div className="w-[144px]">
                  <Select
                    value={selectedInterval}
                    onChange={(value: keyof typeof INTERVAL) => setSelectedInterval(value)}
                    options={[
                      {
                        label: 'Daily',
                        value: INTERVAL.daily,
                      },
                      {
                        label: 'Monthly',
                        value: INTERVAL.monthly,
                      },
                      {
                        label: 'Quarterly',
                        value: INTERVAL.quarterly,
                      },
                      {
                        label: 'Yearly',
                        value: INTERVAL.yearly,
                      },
                    ]}
                  />
                </div>
              );
            }}
          >
            <div className="p-[24px]">
              <CardContent isLoading={isLoading} isError={isError} onRetry={refetch}>
                {series.length > 0 ? (
                  <Chart
                    style={{ height: '100%' }}
                    options={{
                      chart: {
                        type: 'area',
                        height: 200,
                      },
                      title: {
                        text: '',
                      },
                      legend: {
                        verticalAlign: 'top',
                      },
                      xAxis: {
                        categories: date,
                      },
                      yAxis: {
                        title: {
                          text: '',
                        },
                      },
                      series: series as SeriesOptionsType[],
                    }}
                  />
                ) : (
                  <Chart
                    style={{ height: '100%' }}
                    options={{
                      chart: {
                        type: 'area',
                        height: 200,
                      },
                      title: {
                        text: '',
                      },
                      legend: {
                        verticalAlign: 'top',
                      },
                      xAxis: {
                        categories: date,
                      },
                      yAxis: {
                        title: {
                          text: '',
                        },
                      },
                      series: [
                        {
                          name: 'No Data',
                          data: [],
                        },
                      ] as unknown as SeriesOptionsType[],
                    }}
                  />
                )}
              </CardContent>
            </div>
          </Card>
        );
      }}
    />
  );
};
