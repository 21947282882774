import styled from 'styled-components/macro';
import { isMobile } from 'react-device-detect';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  position: relative;
`;

export const FlexContent = styled.div`
  display: flex;
  width: ${isMobile ? '320px' : 'auto'};
  overflow: auto;
`;
