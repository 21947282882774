import { useMachine } from '@xstate/react';
import { useState, useEffect } from 'react';
import { vaPlaceholderMachine } from './machine';
import { CombineAllMessage } from '@model/MessageItem';

export const useVaPlaceholder = (lastMessage: CombineAllMessage, currentMessage = '', vaMessage = '') => {
  const [vaTemp, setVaTemp] = useState<string>('');

  const [current, send] = useMachine(vaPlaceholderMachine, {
    devTools: true,
  });

  // update ref
  useEffect(() => {
    setVaTemp(vaMessage);
  }, [vaMessage]);

  useEffect(() => {
    if (vaMessage !== vaTemp && current.matches('composer.show')) {
      send('COMPOSER_HIDE');
      send('PLACEHOLDER_SHOW');
    }
  }, [vaMessage, current]);

  useEffect(() => {
    const handleWindowClick = (e: MouseEvent) => {
      const composer = document.getElementById('message-composer');
      const katik = document.getElementById('va-icon-wrap');
      if (!composer || !katik) return;
      if (current.matches('composer.show') && composer === e.target) {
        send('COMPOSER_FOCUSING');
        return;
      }
      if (current.matches('composer.focusing') && !currentMessage) {
        send('COMPOSER_SHOW');
        return;
      }

      if (current.matches('composer.texting') && currentMessage) {
        send('COMPOSER_FILLED');
        return;
      }
      if (current.matches('composer.filled') && currentMessage && e.target === composer) {
        send('COMPOSER_TEXTING');
        return;
      }
    };
    window.addEventListener('click', handleWindowClick);
    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  }, [current, currentMessage]);

  useEffect(() => {
    switch (lastMessage.getSender) {
      case 'user':
        // show placeholder
        send('PLACEHOLDER_SHOW');
        break;
      default:
        send('PLACEHOLDER_HIDE');
        send('COMPOSER_FOCUSING');
        break;
    }
  }, []);
  // send-message-btn

  useEffect(() => {
    // composer
    const composer = document.getElementById('message-composer');
    if (current.matches('composer.hide')) {
      composer?.style.setProperty('display', 'none');
      return;
    }

    if (current.matches('composer.show')) {
      composer?.style.setProperty('display', 'block');
      return;
    }
    if (current.matches('composer.focusing') && !currentMessage) {
      composer?.style.setProperty('display', 'block');
      composer?.focus();
      return;
    }
    if (current.matches('composer.focusing') && currentMessage) {
      composer?.style.setProperty('display', 'block');
      composer?.focus();
      send('COMPOSER_TEXTING');
      return;
    }
    if (current.matches('composer.texting') && !currentMessage) {
      composer?.style.setProperty('display', 'block');
      composer?.focus();
      send('COMPOSER_FOCUSING');
      return;
    }

    if (current.matches('composer.filled') && !currentMessage) {
      composer?.style.setProperty('display', 'block');
      composer?.focus();
      send('COMPOSER_FOCUSING');
      return;
    }
  }, [current, currentMessage, vaMessage]);

  return {
    send,
    composer: {
      isShowComposer: current.matches('composer.show'),
      show: () => send('COMPOSER_SHOW'),
      hide: () => send('COMPOSER_HIDE'),
      focus: () => send('COMPOSER_FOCUSING'),
      texting: () => send('COMPOSER_TEXTING'),
      filled: () => send('COMPOSER_FILLED'),
    },
    placeholderHandler: {
      isShowPlaceholder: current.matches('placeholder.show'),
      hidePlaceholder: () => send('PLACEHOLDER_HIDE'),
      showPlaceholder: () => send('PLACEHOLDER_SHOW'),
    },
    katik: {
      isShowKatik: current.matches('katik.show'),
      showKatik: () => send('KATIK_SHOW'),
      hideKatik: () => send('KATIK_HIDE'),
    },
    tootip: {
      isShow: current.matches('tooltip.show'),
      display: () => {
        setTimeout(() => {
          send('TOOLTIP_SHOW');
          setTimeout(() => {
            send('TOOLTIP_HIDE');
          }, 3000);
        }, 500);
        // wait for 3 seconds
      },
    },
  };
};
