import { useRecoilValue } from 'recoil';
import { useMember } from '@providers/MemberProvider';
import { selectedTicketState } from '@store/EngagementUserListAtom';
import { useSetting } from '@providers/SettingProvider';

export const useComposer = () => {
  const { isAdmin } = useMember();

  const selectedTicket = useRecoilValue(selectedTicketState);
  const { member } = useMember();
  const { permission } = useSetting();
  const canChat = () => {
    if (permission.canReply) {
      return true;
    }
    // current login is admin and current filter is assigned to team
    if (isAdmin()) {
      return true;
    }
    return selectedTicket?.agentId === member._id;
  };

  return {
    selectedTicket,
    canChat,
  };
};

export default useComposer;
