import React, { useState, useCallback, useMemo } from 'react';
import { IAssigneeMachineContext } from '@pages/components/Assignee/assigneeMachine';
import { ITeam, ITicket } from '@types';
import { twMerge } from 'tailwind-merge';
import SearchInput from '@components/SearchInput';
import Avatar from '@components/Avatar';

type ITeamTabProps = {
  internalSelectedTeam: string | null;
  ticket: ITicket;
  list: IAssigneeMachineContext['teamData']['rows'];
  onSelectTeam: (team: ITeam) => void;
};

export const TeamTab = ({ internalSelectedTeam, ticket, list, onSelectTeam }: ITeamTabProps) => {
  const [searchText, setSearchText] = useState<string>('');

  const filterTeam = useMemo(() => {
    return list?.filter((item) => {
      return item.name.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [searchText, internalSelectedTeam, list]);

  const highlight = (teamId: string) => {
    if (!ticket) return 'bg-transparent';
    // selected team
    if (ticket.team === teamId) return 'border-[#04BE8C] bg-[#e6f8f3] opacity-50';
    if (internalSelectedTeam === teamId) return 'border-[#04BE8C] bg-[#e6f8f3]';
    return 'bg-transparent';
  };

  const _renderList = useCallback(() => {
    if (!list) return null;
    return (
      <div className="flex flex-wrap gap-[16px] mt-[16px]">
        {filterTeam?.map((item) => {
          const mButton = twMerge(
            'flex p-[12px] rounded-[8px] border border-[#E5E5E5] border-solid min-w-[224px] overflow-hidden gap-x-[8px] items-center',
            highlight(item._id),
          );
          return (
            <button
              key={item._id}
              className={mButton}
              title={item.name}
              onClick={() => {
                onSelectTeam(item);
              }}
            >
              <Avatar
                name={item.name}
                width={32}
                height={32}
                customName={(name) => {
                  if (!name) return '-';
                  // return 2 string from name
                  if (name.length > 1) {
                    return `${name[0]}${name[1]}`;
                  }
                  return `${name[0]}`;
                }}
              />
              <div>{item.name}</div>
            </button>
          );
        })}
      </div>
    );
  }, [filterTeam, internalSelectedTeam]);

  return (
    <div>
      <div className="flex gap-x-[8px] mt-[24px] w-[418px]">
        <SearchInput
          className="min-w-[230px] max-w-[230px]"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onClear={() => {
            setSearchText('');
          }}
        />
      </div>
      <div className="max-h-[500px] overflow-auto">{_renderList()}</div>
    </div>
  );
};
