import React, { useCallback, useMemo, useRef } from 'react';
import { Container, UserDetail, UserName, TicketNumber, ActionBox, CheckIcon, ComposerContainer } from './style';
import Avatar from '@components/Avatar';
import { useTicketManager } from '@providers/TicketManagerProvider';
import { LoadingWrap } from '@components/Loading/style';
import LoadingCircle from '@components/Loading';
import { Link, Redirect, useHistory } from 'react-router-dom';
import MessageBoxProvider from '@providers/MessageBoxProvider';
import MessageBox from './MessageBox';
import { getQueryString } from '@utils/getQueryString';
import Composer from './Composer';
import { useMember } from '@providers/MemberProvider';
import { useTheme } from 'styled-components/macro';
import { TopbarBox } from '@pages/components/TopbarBox/style';
import AbstractBottomModal from '@components/BottomModal/AbstractBottomModal';
import AssineeTab from './AssigneeTab';
import ConfirmSelect from './ConfirmSelect';
import { MOBILE_ROUTE } from '@configs/constants';
import { Icon, DisabledIcon } from '@components/UtilsComponent';
import ResolveDrawer from './ResolveDrawer';
import {
  faSpinnerThird,
  faArrowLeft as fasArrowLeft,
  faUserFriends as fasUserFriends,
  faCheck as fasCheck,
} from '@fortawesome/pro-solid-svg-icons';
import { useSetting } from '@providers/SettingProvider';
import { IconMove } from '@components/IconMove';
import { canDoTeamFollowUp } from '@utils/followUp';
import { useFollowUp } from '@hooks/useFollowUp';
import { updateFollowUp, updateTeamFollowUp } from '@api/livechat/EngagementMessage';
import { faStar as fasStar, faFlag as fasFlag } from '@fortawesome/pro-solid-svg-icons';
import { faStar as farStar, faFlag as farFlag } from '@fortawesome/pro-regular-svg-icons';
import { useQueryParams } from '@hooks/useQueryParams';

const EngagementMessage: React.FC = () => {
  const sheetRefHeight = useRef<number | undefined>();
  const theme: any = useTheme();
  const history = useHistory();
  const { member } = useMember();
  const messageTime = getQueryString(history.location.search, 'messageTime');
  const { setting, permission } = useSetting();

  const {
    current,
    canResolveTicket,
    handleInstanceResolve,
    handleResolveTicket,
    handleSelectAssignee,
    handleCloseAssigneeModal,
    handleTabChange,
    handleSelectTeam,
    handleSelectMember,
    handleCancelConfirm,
    handleConfirmAssignee,
    handleOpenResolveDrawer,
    canChat,
  } = useTicketManager();

  const { context } = current;
  const isTicketOpen = context.ticket?.status === 'open';
  const isSelectingAssignee = current.matches('idle.selectingAssignee');
  const isConfirmAssignee = current.matches('idle.selectingAssignee.confirmSelectAssignee');
  const isAssignFailed = current.matches('idle.selectingAssignee.assignFailed');
  const isFechingTicket = current.matches('fetchingTicket');
  const isAssignSuccess = current.matches('idle.selectingAssignee.assignSuccess');
  const query = useQueryParams();
  const prevTab = query.get('prev');
  const getMessageTime = (time: string | null) => {
    if (!time) {
      return null;
    }
    const convertTime = Number(time);
    if (Number.isNaN(convertTime)) {
      return null;
    }
    return convertTime;
  };

  const [followUp, _followUpMutation, onFollowUp] = useFollowUp({
    ticket: current.context.ticket,
    member,
    updateFollowUpFn: updateFollowUp,
    initCondition: (ticket, member, setter) => {
      const isFollowUp = ticket?.followUps?.some((item: string) => item === member._id);
      if (isFollowUp) {
        setter(true);
        return true;
      }
      return false;
    },
  });
  const [teamFollowUp, _teamFollowUpMutation, onTeamFollowUp] = useFollowUp({
    ticket: current.context.ticket,
    member,
    updateFollowUpFn: updateTeamFollowUp,
    initCondition: (ticket, _member, setter) => {
      if (ticket.isTeamFollowUp) {
        setter(true);
        return true;
      }
      return false;
    },
  });

  const userName = useMemo(() => {
    if (context?.ticket?.userInfo?.displayName) {
      return context?.ticket?.userInfo?.displayName;
    }

    return context?.ticket?.userInfo?.name;
  }, [context.ticket]);

  const canNotDoTeamFollowUp = useMemo(() => {
    // ticket is open
    if (isTicketOpen) return true;
    if (permission.canTeamFollowUp) return false;
    if (!current.context.ticket || !member) return false;
    return !canDoTeamFollowUp(current.context.ticket, member);
  }, [member, current.context.ticket]);
  const _renderSheetContent = () => {
    if (current.hasTag('confirmSelectAssignee')) {
      return (
        <ConfirmSelect
          ticket={context.ticket!}
          height={sheetRefHeight.current}
          handleBack={handleCancelConfirm}
          handleConfirmAssignee={handleConfirmAssignee}
          isLoading={isConfirmAssignee}
          isError={isAssignFailed}
        />
      );
    }
    return (
      <AssineeTab
        handleOnDismiss={handleCloseAssigneeModal}
        height={sheetRefHeight.current}
        members={context.members}
        teams={context.teams}
        handleTabChange={handleTabChange}
        handleSelectTeam={handleSelectTeam}
        handleSelectMember={handleSelectMember}
        isLoading={current.hasTag('fetching-assignee')}
      />
    );
  };

  const _renderResolveIcon = useCallback(() => {
    if (current.matches('idle.resolvingTicket')) {
      return <Icon icon={faSpinnerThird} spin style={{ color: theme.PRIMARY }} />;
    }
    if (canResolveTicket && !setting?.isWrapUpTicketRequired) {
      return <CheckIcon onClick={handleInstanceResolve} icon={fasCheck} />;
    }
    if (canResolveTicket && setting?.isWrapUpTicketRequired) {
      return (
        <CheckIcon
          onClick={() => {
            handleOpenResolveDrawer(true);
          }}
          icon={fasCheck}
        />
      );
    }

    return <DisabledIcon icon={fasCheck} />;
  }, [canResolveTicket, handleOpenResolveDrawer, handleResolveTicket, setting?.isWrapUpTicketRequired, current]);

  if (isAssignSuccess) {
    return <Redirect to={MOBILE_ROUTE.RECENTS} />;
  }

  if (isFechingTicket && !context.ticket) {
    return (
      <Container>
        <LoadingWrap>
          <LoadingCircle />
        </LoadingWrap>
      </Container>
    );
  }
  return (
    <Container>
      <TopbarBox>
        <UserDetail>
          <Link to={`/m/recents?status=${prevTab}`}>
            <Icon icon={fasArrowLeft} />
          </Link>
          <div>
            <Avatar width={32} height={32} img={context?.ticket?.userInfo?.image ?? ''} />
          </div>
          <Link to={`/m/user/${context.ticket?.userId}`}>
            <div className="overflow-ellipsis  w-[calc(100vw-200px)]">
              <UserName ellipsis={true}>{userName ?? ''}</UserName>
            </div>
            <div>
              <TicketNumber>#{context?.ticket?.ticketNumber ?? ''}</TicketNumber>
            </div>
          </Link>
        </UserDetail>

        <ActionBox>
          <IconMove
            isActive={followUp}
            isLoading={false}
            activeIcon={fasStar}
            inactiveIcon={farStar}
            activeColor={'#FFB400'}
            inActiveColor={theme.GREY_5}
            onClick={onFollowUp}
          />
          <IconMove
            isActive={teamFollowUp}
            isLoading={false}
            activeIcon={fasFlag}
            inactiveIcon={farFlag}
            activeColor={'#f72c40'}
            inActiveColor={theme.GREY_5}
            isDisabled={canNotDoTeamFollowUp}
            onClick={onTeamFollowUp}
          />
          {canChat() ? (
            <Icon onClick={handleSelectAssignee} icon={fasUserFriends} />
          ) : (
            <DisabledIcon icon={fasUserFriends} />
          )}
          {_renderResolveIcon()}
        </ActionBox>
      </TopbarBox>

      <MessageBoxProvider
        ticket={context.ticket!}
        user={context.user!}
        member={member}
        messageTime={getMessageTime(messageTime)}
        options={{ isSocket: true, asReader: true, isTicketManagementMode: false }}
      >
        <MessageBox />
        <ComposerContainer>
          <Composer selectedUser={context.user!} />
        </ComposerContainer>
      </MessageBoxProvider>

      <AbstractBottomModal
        snapPoints={({ maxHeight }: { maxHeight: number }) => {
          const sheetHeight = Math.floor(maxHeight / 1.2);
          sheetRefHeight.current = sheetHeight;
          return [sheetHeight, maxHeight];
        }}
        open={isSelectingAssignee}
        onDismiss={handleCloseAssigneeModal}
      >
        {_renderSheetContent()}
      </AbstractBottomModal>
      <ResolveDrawer />
    </Container>
  );
};

export default EngagementMessage;
