import React from 'react';
import { Col, Row } from 'antd';
import { BaseTag, HiddenMenuWrap, BaseTagClickable, HiddenMenu } from '../style';
import { ITicketTagConsole } from '@types';
import { Dropdown } from 'antd';
import TagWithRemove from '../TagWithRemove';
import { Font14G1W700 } from '@components/UtilsComponent';

export type ITicketTagList = {
  tags: ITicketTagConsole[];
  displaySize: number;
  canRemove?: boolean;
  tagRefs?: React.MutableRefObject<{ id: string; el: HTMLElement }[]>;
  showHiddenMenu?: boolean;
  onRemoveTag?: (tag: ITicketTagConsole) => void;
};
export const TicketTagList = ({
  tags,
  displaySize,
  tagRefs,
  canRemove,
  showHiddenMenu,
  onRemoveTag,
}: React.PropsWithChildren<ITicketTagList>) => {
  const showTags = tags.slice(0, displaySize);
  const hiddenSize = tags.length - displaySize;
  const _renderTag = (tag: ITicketTagConsole) => {
    if (canRemove && onRemoveTag) {
      return <TagWithRemove label={tag.label} isLoading={tag.loading} handleRemoveTag={() => onRemoveTag(tag)} />;
    }
    return (
      <BaseTag>
        <Font14G1W700>{tag.value}</Font14G1W700>
      </BaseTag>
    );
  };
  const _renderOverlay = () => {
    const remainTags = tags.slice(displaySize, tags.length);

    return (
      <HiddenMenu>
        <HiddenMenuWrap>
          {remainTags.map((tag) => {
            return _renderTag(tag);
          })}
        </HiddenMenuWrap>
      </HiddenMenu>
    );
  };

  const _renderHiddenMenu = () => {
    if (showHiddenMenu && hiddenSize > 0) {
      return (
        <Dropdown trigger={['click']} overlay={_renderOverlay()}>
          <Col>
            <BaseTagClickable>
              <Font14G1W700>+{hiddenSize}</Font14G1W700>
            </BaseTagClickable>
          </Col>
        </Dropdown>
      );
    }
    if (hiddenSize > 0) {
      return (
        <Col>
          <BaseTag>
            <Font14G1W700>+{hiddenSize}</Font14G1W700>
          </BaseTag>
        </Col>
      );
    }
    return null;
  };

  return (
    <>
      <Row wrap={false} gutter={[0, 8]}>
        {tags.length > 0 && (
          <Row wrap={false} gutter={[0, 8]}>
            {showTags.map((tag) => (
              <Col
                ref={(e) => {
                  if (e && tagRefs) {
                    tagRefs.current.push({
                      id: tag.id,
                      el: e,
                    });
                  }
                }}
                key={`${tag.id}`}
              >
                <React.Fragment>{_renderTag(tag)}</React.Fragment>
              </Col>
            ))}
          </Row>
        )}
        {_renderHiddenMenu()}
      </Row>
    </>
  );
};

export default TicketTagList;
