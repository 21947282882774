import { DATE_INTERVAL, EDateInterval } from '@components/DateRangePickerV2';
import { IOption } from '@components/Select';
import dayjs from 'dayjs';
import { useState, useEffect } from 'react';

export type IFilterState = {
  dateInterval: IOption;
  startIsoStringDate: dayjs.Dayjs;
  endIsoStringDate: dayjs.Dayjs;
  channels: string[];
  teams: string[];
  assignees: string[];
  tags: string[];
};

export type ISubmitCustomDatePayload = {
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
  startTime: dayjs.Dayjs;
  endTime: dayjs.Dayjs;
};

export const useDashboardFilter = () => {
  const [clearDatePicker, setClearDatePicker] = useState(false);
  const [openDatePickers, setOpenDatePickers] = useState(false);

  const [filter, setFilter] = useState<IFilterState>({
    dateInterval: DATE_INTERVAL[1],
    // last 24 hours
    startIsoStringDate: dayjs().subtract(1, 'day'),
    // end of today
    endIsoStringDate: dayjs(),
    // channel
    channels: [],
    teams: [],
    assignees: [],
    tags: [],
  });
  return {
    filter,
    onChangeFilter: <K extends keyof IFilterState>(key: K, value: IFilterState[K]) => {
      setFilter((prev) => ({
        ...prev,
        [key]: value,
      }));
    },
    dateState: {
      clearDatePicker,
      openDatePickers,
      onToggleDatePickers: () => {
        setOpenDatePickers((prev) => !prev);
      },
      onClearDatePicker: (status: boolean) => {
        setClearDatePicker(status);
      },
      onChangeCustomDate: (payload: ISubmitCustomDatePayload) => {
        setFilter((prev) => ({
          ...prev,
          startIsoStringDate: payload.startTime,
          endIsoStringDate: payload.endTime,
          dateInterval: DATE_INTERVAL[0],
        }));
      },
      onChangeDateInterval: (item: (typeof DATE_INTERVAL)[0]) => {
        switch (item.value) {
          case EDateInterval.LAST_24_H:
            setFilter((prev) => ({
              ...prev,
              dateInterval: item,
              startIsoStringDate: dayjs().subtract(1, 'day'),
              endIsoStringDate: dayjs(),
            }));
            break;
          case EDateInterval.LAST_7_D:
            setFilter((prev) => ({
              ...prev,
              dateInterval: item,
              startIsoStringDate: dayjs().subtract(7, 'day'),
              endIsoStringDate: dayjs(),
            }));
            break;
          case EDateInterval.LAST_30_D:
            setFilter((prev) => ({
              ...prev,
              dateInterval: item,
              startIsoStringDate: dayjs().subtract(30, 'day'),
              endIsoStringDate: dayjs(),
            }));
            break;
          case EDateInterval.LAST_3_M:
            setFilter((prev) => ({
              ...prev,
              dateInterval: item,
              startIsoStringDate: dayjs().subtract(3, 'month'),
              endIsoStringDate: dayjs(),
            }));
            break;
          case EDateInterval.LAST_6_M:
            setFilter((prev) => ({
              ...prev,
              dateInterval: item,
              startIsoStringDate: dayjs().subtract(6, 'month'),
              endIsoStringDate: dayjs(),
            }));
            break;
          // last 12 M
          default:
            setFilter((prev) => ({
              ...prev,
              dateInterval: item,
              startIsoStringDate: dayjs().subtract(12, 'month'),
              endIsoStringDate: dayjs(),
            }));
            break;
        }
      },
    },
  };
};
