import { sendMessage, sendTemplate } from '@api/livechat/EngagementMessage';
import { AnyEventObject } from 'xstate';
import { IMessageBoxMachineContext } from '../messageBoxMachine';

//agent send messages
export const sendAgentMessage = async (_context: IMessageBoxMachineContext, event: AnyEventObject) => {
  const { memberId, messagePayload } = event;

  try {
    //check online
    if (!window.navigator.onLine) {
      throw 'No internet connection';
    }
    const { createdAt, ...rest } = messagePayload;
    const result = await sendMessage(memberId, {
      ...rest,
    });
    return {
      result,
    };
  } catch (error) {
    throw {
      error,
    };
  }
};

export const sendAgentTemplate = async (_context: IMessageBoxMachineContext, event: AnyEventObject) => {
  const { messagePayload } = event;
  try {
    //check online
    if (!window.navigator.onLine) {
      throw 'No internet connection';
    }
    const result = await sendTemplate(messagePayload);
    return {
      result,
    };
  } catch (error) {
    throw {
      error,
    };
  }
};
