import React, { useMemo } from 'react';
import useMessageComposer from './useMessageComposer';
import { useFile } from '@providers/MessageBoxProvider/useFile';
import MessageComposerComp from '@pages/components/MessageComposer';
import { useComposerManager } from '@providers/ComposerManagerProvider';
import { useRecoilValue } from 'recoil';
import { currentFilterState, selectedTicketState, selectedUserState } from '@store/EngagementUserListAtom';
import { getInputMaxLength } from '@utils/getInputMaxLength';
import { useMessageBox } from '@providers/MessageBoxProvider';
import { useMember } from '@providers/MemberProvider';
import { ETicketFilterStatus } from '@enums/TicketFilter';

const MessageComposer: React.FC = () => {
  const { member, isAgent, isSupervisor, isAdmin } = useMember();
  const { current } = useMessageBox();
  const { chatHistory } = current.context;
  const { preview, composer } = useComposerManager();
  const { onSelectTemplate } = preview;
  const { onToggleComposer } = composer;
  const { txt, handleInputChange, handleSendMessage, handleSendTemplate } = useMessageComposer();
  const { fileHandler } = useFile((onUploadFile) => {
    onUploadFile();
  });
  const selectedUser = useRecoilValue(selectedUserState);
  const selectedTicket = useRecoilValue(selectedTicketState);
  const currentFilter = useRecoilValue(currentFilterState);
  const enableVa = useMemo(() => {
    // agent, supvervisor only assigned to me
    // admin open all
    // if BE return error display error
    if ((isAgent() || isSupervisor()) && selectedTicket?.agentId === member._id) {
      return true;
    }

    if (isAdmin()) {
      return true;
    }
    return false;
  }, [currentFilter, selectedTicket]);
  const inputMaxLength = useMemo(() => {
    return getInputMaxLength(selectedTicket?.channelType);
  }, [selectedTicket]);

  const lastMessage = useMemo(() => {
    return chatHistory.messages[chatHistory.messages.length - 1];
  }, [chatHistory.messages]);

  return (
    <MessageComposerComp
      ticketId={selectedTicket?._id}
      platform={selectedUser?.channel?.type.toLocaleLowerCase()}
      maxLength={inputMaxLength}
      messageBoxHandler={{
        textMessage: txt,
        disabledSendButton: txt.length === 0 || txt.trim() === '',
        handleTextInputChange: handleInputChange,
        handleSendMessage: handleSendMessage,
        handleSendTemplate: (template) => {
          handleSendTemplate(template);
          // close composer
          onToggleComposer();
          // close preview
          onSelectTemplate(null);
        },
      }}
      enableVa={enableVa}
      lastMessage={lastMessage}
      fileHandler={fileHandler}
    />
  );
};

export default MessageComposer;

// The co-pilot is disabled in this team. Please contact admin to enabled the feature
